import Img from 'gatsby-image';
import React from 'react';
import clientConfig from '../../../client-config';
import { getFluidGatsbyImage } from 'gatsby-source-sanity';

export default ({ node }: { node: any }) => {
  if (!node || !node.asset || !node.asset._ref) {
    return null;
  }
  const fluidProps = getFluidGatsbyImage(node.asset._ref, { maxWidth: 675 }, clientConfig.sanity);
  return (
    <>
    <figure
      style={{ boxShadow: '0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 10px -5px rgba(0, 0, 0, 0.23)',maxWidth: '750px',marginLeft: 'auto',marginRight: 'auto', marginBottom: '10px'}}
    >
      <Img fluid={fluidProps} alt={node.alt} />
    </figure>
    <div
      style={{textAlign: 'left',alignItems: 'center',color: '#838181',fontSize: '14px',fontStyle: 'italic',marginBottom: '20px'}}
    >
      <figcaption>{node.caption}</figcaption>
    </div>
    </>
  );
};
