import BasePortableText from '@sanity/block-content-to-react';
import React from 'react';
import clientConfig from '../../../client-config';
import Serializers from './Serializers';

const PortableText = ({ blocks, name }: { blocks: any; name?: string }) => (
  <BasePortableText
    blocks={blocks}
    className={name}
    serializers={Serializers}
    renderContainerOnSingleChild={!!name}
    {...clientConfig.sanity}
  />
);

export default PortableText;
