import Figure from './Figure';
import * as React from 'react';
import YouTube from 'react-youtube';
import getYouTubeId from 'get-youtube-id';
import InstagramEmbed from 'react-instagram-embed';

const Serializers = {
  types: {
    authorReference: ({ node }: { node: any }) => <span>{node.author.name}</span>,
    mainImage: Figure,
    figure: Figure,
    youtube: ({ node }: any) => {
      const { url } = node;
      const id = getYouTubeId(url);
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }} className="mb-24">
          <YouTube videoId={id || 'dQw4w9WgXcQ'} />
        </div>
      );
    },
    instagramPost: ({ node }: any) => {
      const { url } = node;
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }} className="mb-24">
          <InstagramEmbed
            url={url}
            maxWidth={320}
            containerTagName="div"
            injectScript
            hideCaption={true}
          />
        </div>
      );
    },
    code: ({ node }: any) => (
      <pre className="codeBlock">
        <code>{node.code}</code>
      </pre>
    ),
  },
};

export default Serializers;
