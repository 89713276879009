import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import PortableText from '../components/portabletext/PortableText';
import SEO from '../components/seo/SEO';
import styles from './Post.module.scss';
import Arrow from '../images/icons/arrow.svg';
import Layout from '../components/layout/Layout';
import {format} from 'date-fns'

//TODO Add author info to blog posts + add SEO tag
export const query = graphql`
  query getBlogBySlug($slug: String!) {
    sanityPost(slug: { current: { eq: $slug } }) {
      title
      publishedAt
      slug {
        current
      }
      _rawBody
      mainImage {
        alt,
        caption
        asset {
          fluid {
            ...GatsbySanityImageFluid_withWebp
          }
        }
      }
    }
  }
`;

const NewsPost = (props: any) => {
  const { data } = props;
  return (
    <>
      <SEO title={data.sanityPost?.title} />
      <Layout>
        <div className={styles.backButtonContainer}>
          <Link to="/news">
            <div className={styles.backButton}>
              <div>
                <img className={styles.arrow} src={Arrow} alt="backbutton" />{' '}
              </div>
              <div className={styles.backButtonText}>Back to news section</div>
            </div>
          </Link>
        </div>
        <div className={styles.imageContainer}>
            <>
              <figure className={`headerImageHorizontal fullWidthMobile ${styles.image}`}>
                {data.sanityPost?.mainImage &&
                  data.sanityPost?.mainImage.asset &&
                  data.sanityPost?.mainImage.asset.fluid && (
                    <Img
                      fluid={data.sanityPost?.mainImage.asset.fluid}
                      alt={data.sanityPost?.mainImage.alt}
                    />
                  )}
              </figure>
              <div
                style={{textAlign: 'left',display: 'flex',flexDirection: 'column',alignContent: 'center',maxWidth: '750px',color: '#838181',fontSize: '14px',fontStyle: 'italic', marginLeft: 'auto',marginRight: 'auto',marginTop: '10px', marginBottom: '20px'}}
              >
                <figcaption>{data.sanityPost?.mainImage.caption}</figcaption>
              </div>
            </>
          </div>
        <div className="grid_vertical_padding sectionVeryLong">
          <div className={styles.textContainer}>
            <h2 className={styles.dateStamp}> Published {format(data.sanityPost?.publishedAt, 'DD.MM.YYYY')}. </h2>
            <h1 className={styles.welcomeText}>{data.sanityPost?.title}</h1>
            {data.sanityPost?._rawBody && <PortableText blocks={data.sanityPost?._rawBody} />}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default NewsPost;
